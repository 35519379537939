<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :width="800"
        :height="400"
    />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)



export default {
    name: "AssessmentChart",
    components: { Bar },
    props: ['values', 'labels'],
    data() {
        return {
            chartData: {
                labels: this.labels,
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        data: this.values
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    mounted() {
       // this.chartData.labels = this.values
    }
}
</script>

<style scoped>

</style>
