<template>
    <user-dashboard-layout>
        <v-row>
            <v-col class="text-right">
<!--                <v-btn @click="$router.push({name: 'dashboard.consultations.create'})">Create</v-btn>-->
            </v-col>
        </v-row>
        <v-row v-if="assessments && assessments.length > 0">
            <assessment-chart :labels="assessmentLabels" :values="assessmentValues"   />
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="assessments"
            :items-per-page="15"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import Assessment from "../../../models/Assessment";
    import AssessmentChart from "../../../components/dashboard/AssessmentChart";


    export default {
        name: "users-index",
        components: {AssessmentChart, UserDashboardLayout},
        computed: {
            assessmentValues() {
                return this.$_.map(this.assessments, 'score');
            },
            assessmentLabels() {
                return this.$_.map(this.assessments, 'created_at');
            }
        },
        data: function () {
            return {
                assessments: [],
                loading: false,
                headers: [
                    { text: 'Created at', value: 'created_at' },
                    { text: 'Score', value: 'score' },
                ],
            }
        },
        methods: {
            handleClickRow() {
           //     this.$router.push({name: 'admin.assessments.show', params: {id: assessment.id}})
            },
            async getConsultations() {
                this.loading = true
                let assessments = new Assessment();
                this.assessments = await assessments.where('company_id', this.$auth.user().company.id).get()
                this.loading = false
            }
        },
        async mounted() {
            await this.getConsultations()
        },
    }
</script>

<style scoped>

</style>
